export const groupBy = (xs, key) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const onlyUnique = (value, index, array) => array.indexOf(value) === index;

export const objectToFormData = (obj, formData = new FormData(), namespace = "") => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let propName = namespace ? `${namespace}[${key}]` : key;
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        if (obj[key] instanceof File) {
          formData.append(propName, obj[key]);
        } else {
          objectToFormData(obj[key], formData, propName);
        }
      } else {
        formData.append(propName, obj[key]);
      }
    }
  }
  return formData;
};

export const searchMenu = (array = [], searchTerm = "") => {
  const copiedArray = array

  return copiedArray.filter(item => {
    if (!item.submenu) {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase())
    } else {
      const filteredSubmenus = item.submenu.filter(submenu => (
        submenu.name.toLowerCase().includes(searchTerm.toLowerCase())
      ))

      return (
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) || filteredSubmenus.length > 0
      )
    }
  }).map(item => {
    if (!item.submenu) return item

    if (!item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      const filteredSubmenus = item.submenu.filter(submenu => (
        submenu.name.toLowerCase().includes(searchTerm.toLowerCase())
      ))

      return {
        ...item,
        submenu: filteredSubmenus,
        avoidSingle: Array.isArray(item.submenu) && item.submenu.length > 1
      }
    }

    return item
  })
};

export const isSVGString = (str) => {
  const svgRegex = /<svg(.*?)<\/svg>/i;
  return svgRegex.test(str);
};

export const getCookies = () => {
  const cookieData = document.cookie;
  if (!cookieData) return {};

  const cookiesArray = cookieData.split(';');
  const cookies = cookiesArray.reduce((cookies, cookie) => {
    const [key, value] = cookie.trim().split('=');
    cookies[key] = decodeURIComponent(value);
    return cookies;
  }, {});

  return cookies;
};

export const clearCookies = () => {
  const cookies = getCookies();
  const cookieKeys = Object.keys(cookies);
  
  // Iterate through each cookie key and set its expiration date to the past
  cookieKeys.forEach(key => {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};