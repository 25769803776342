import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import MenuItemTextHighlighter from "src/components/navigation/MenuItemTextHighlighter";
import { classNames } from "src/helpers/classNames";

const TopMenuItemLink = ({ data, page_id, group_id, searchTerm, isTopLevel = false, handleClick = () => {} }) => {
  return (
    <>
      <div className={classNames("relative", data.type !== "internal" ? "group/link" : "")}>
        {data.type === "internal" ? (
          <Link
            onClick={() => handleClick(data.groupId)}
            to={data.link}
            className={classNames(
              "flex min-h-10 w-full items-center justify-between rounded px-4 py-2 transition-all hover:bg-leftNavActiveColor hover:text-leftNavActiveTextColor",
              (
                data.sub
                  ? (data._id === page_id || data.slug === page_id) && data.groupId === group_id
                  : ((data._id === page_id || data.slug === page_id) && !data.groupId && !group_id) || (data.groupId && group_id && data.groupId === group_id && (data._id === page_id || data.slug === page_id))
              )
                ? "!bg-leftNavActiveColor text-leftNavActiveTextColor"
                : "text-leftNavTextColor",
            )}>
            <span className={classNames("text-base !leading-none", isTopLevel ? "font-normal" : "font-medium")}>
              <MenuItemTextHighlighter
                text={data.name}
                highlight={searchTerm}
              />
            </span>
          </Link>
        ) : (
          <a
            href={data.link}
            target={data.page_link_newtab ? "_blank" : "_self"}
            rel="noreferrer"
            className={classNames(
              "flex h-10 w-full items-center justify-between rounded px-3 text-leftNavTextColor transition-all hover:text-blue-700 hover:text-leftNavActiveTextColor hover:underline md:text-sm",
              (data._id === page_id || data.slug === page_id) && !group_id ? "!bg-leftNavActiveColor text-leftNavActiveTextColor" : "text-leftNavTextColor",
            )}>
            <span className={classNames("whitespace-nowrap text-base", isTopLevel ? "font-normal" : "font-medium")}>
              {" "}
              <MenuItemTextHighlighter
                text={data.name}
                highlight={searchTerm}
              />
            </span>
            {<ArrowTopRightOnSquareIcon className={classNames("ml-2 h-5 w-5 stroke-2 md:h-4 md:w-4")} />}
          </a>
        )}
      </div>
    </>
  );
};

export default TopMenuItemLink;
