import { useEffect, useState } from "react";
import LeftMenuItemGroupTitle from "src/components/navigation/leftNavigation/LeftMenuItemGroupTitle";
import LeftMenuItemLink from "src/components/navigation/leftNavigation/LeftMenuItemLink";
import { classNames } from "src/helpers/classNames";

const LeftMenuItemGroup = ({ data, group_id, page_id, searchTerm }) => {
  const [isSubmenu, setIsSubmenu] = useState(data.isOpen ? data.isOpen : false);
  const [isSubmenuSearchOpened, setIsSubmenuSearchOpened] = useState(false);
  const [imageClassName, setImageClassName] = useState("");
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (searchTerm === "") {
      setIsSubmenuSearchOpened(false);
      return;
    }

    data.submenu.forEach((singleLink) => {
      if (singleLink.name.includes(searchTerm)) {
        setIsSubmenuSearchOpened(true);
        return;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    let visibleMenuItems = data.submenu;
    if (searchTerm) {
      let lowercaseSearchTerm = searchTerm.toLowerCase();
      visibleMenuItems = data.submenu.filter((subitem) => {
        return subitem.name.toLowerCase().includes(lowercaseSearchTerm);
      });
    }
    setMenuItems(visibleMenuItems);
  }, [data.submenu, searchTerm]);

  return (
    <div className={classNames("group/main relative")}>
      <LeftMenuItemGroupTitle
        data={data}
        imageClassName={imageClassName}
        setImageClassName={setImageClassName}
        group_id={group_id}
        page_id={page_id}
        dropdownArrowClass={isSubmenu || isSubmenuSearchOpened ? "" : "-rotate-90"}
        setIsSubmenu={setIsSubmenu}
        isSubmenu={isSubmenu}
        searchTerm={searchTerm}
        avoidUnderlineHover={data.submenu.length > 1}
      />
      {Array.isArray(data.submenu) && data.submenu.length > 1 && (
        <div className={classNames("relative overflow-hidden pl-5 transition-all duration-200", isSubmenu || isSubmenuSearchOpened ? "visible max-h-[20000px] space-y-2 opacity-100" : "invisible max-h-0 opacity-0")}>
          {menuItems.map((subitem) => (
            <LeftMenuItemLink
              key={subitem._id}
              data={subitem}
              group_id={group_id}
              page_id={page_id}
              searchTerm={searchTerm}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default LeftMenuItemGroup;
