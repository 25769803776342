import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Image from "src/components/elements/Image";
import MenuItemTextHighlighter from "src/components/navigation/MenuItemTextHighlighter";
import { isSVGString } from "src/helpers";
import { classNames } from "src/helpers/classNames";

const LeftMenuItemLink = ({ data, page_id, group_id, searchTerm, isTopLevel = false, image = null, imageClassName = "", setImageClassName = () => {} }) => {
  return (
    <>
      <div className={classNames("relative", data.type !== "internal" ? "group/link" : "")}>
        {data.type === "internal" ? (
          <Link
            to={data.link}
            className={classNames(
              "flex h-10 w-full items-center justify-between rounded px-3 transition-all hover:bg-leftNavActiveColor hover:text-leftNavActiveTextColor",
              (
                data.sub
                  ? (data._id === page_id || data.slug === page_id) && data.groupId === group_id
                  : ((data._id === page_id || data.slug === page_id) && !data.groupId && !group_id) || (data.groupId && group_id && data.groupId === group_id && (data._id === page_id || data.slug === page_id))
              )
                ? "!bg-leftNavActiveColor text-leftNavActiveTextColor"
                : "text-leftNavTextColor",
            )}>
            <div className="flex items-center">
              {image && (
                <div className={classNames("nav-icon mr-3 h-5 w-5 flex-shrink-0", imageClassName)}>
                  {isSVGString(image) ? (
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: image }}
                    />
                  ) : (
                    <Image
                      src={image}
                      alt={"Menu image"}
                      classes={imageClassName}
                      onError={() => setImageClassName("hidden")}
                    />
                  )}
                </div>
              )}
              <span className={classNames("truncate whitespace-nowrap text-base", isTopLevel ? "font-normal" : "font-medium")}>
                <MenuItemTextHighlighter
                  text={data.name}
                  highlight={searchTerm}
                />
              </span>
            </div>
          </Link>
        ) : (
          <a
            href={data.link}
            target={data.page_link_newtab ? "_blank" : "_self"}
            rel="noreferrer"
            className={classNames(
              "flex h-10 w-full items-center justify-between rounded px-3 text-leftNavActiveTextColor underline transition-all hover:bg-leftNavActiveColor hover:text-blue-700 hover:text-leftNavActiveTextColor",
              (data._id === page_id || data.slug === page_id) && !group_id ? "!bg-leftNavActiveColor text-leftNavActiveTextColor" : "text-leftNavTextColor",
            )}>
            <div className="flex items-center">
              {image && (
                <div className={classNames("nav-icon mr-3 h-5 w-5 flex-shrink-0", imageClassName)}>
                  {isSVGString(image) ? (
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: image }}
                    />
                  ) : (
                    <Image
                      src={image}
                      alt={"Menu image"}
                      classes={imageClassName}
                      onError={() => setImageClassName("hidden")}
                    />
                  )}
                </div>
              )}

              <span className={classNames("truncate whitespace-nowrap text-base", isTopLevel ? "font-normal" : "font-medium")}>
                {" "}
                <MenuItemTextHighlighter
                  text={data.name}
                  highlight={searchTerm}
                />
              </span>
              {<ArrowTopRightOnSquareIcon className={classNames("ml-2 h-5 w-5 stroke-2")} />}
            </div>
          </a>
        )}
      </div>
    </>
  );
};

export default LeftMenuItemLink;
