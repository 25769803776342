import { useContext, useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import BrowserEmbedError from "src/components/embedTypes/BrowserEmbedError";
import ContentError from "src/components/embedTypes/ContentError";
import ExternalLinkEmbed from "src/components/embedTypes/ExternalLinkEmbed";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { AuthContext } from "src/context/auth";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import { renderToString } from "react-dom/server";

const Dashboard = ({ nested }) => {
  const { user } = useContext(AuthContext);
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const navigate = useNavigate();
  // const { workspace_id, group_id, page_id } = params;
  const { workspace_id, group_id, page_id } = useParams();
  const [loadingPage, setLoadingPage] = useState(true);
  const [srcDoc, setSrcDoc] = useState("");
  const [maxWidth, setMaxWidth] = useState("100%");
  const [pageType, setPageType] = useState("");
  const [pageError, setPageError] = useState(null);

  const pageErrorTypes = {
    incorrect_credentials: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the credentials that were added were invalid.",
    },
    incorrect_filters: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the filters that were added were invalid or not filled out.",
    },
  };

  useEffect(() => {
    const handleResizeMessage = (event) => {
      // Ensure the message is from a trusted source/origin
      const data = event.data;
      if (data && data.method === "notifyFrameSize") {
        // Adjust the iframe height
        const iframe = document.getElementById("iframe");
        if (iframe) {
          iframe.height = `${data.params.height}px`;
        }
      }
    };

    if (maxWidth !== "100%") {
      window.addEventListener("message", handleResizeMessage);
    }

    return () => {
      if (maxWidth !== "100%") {
        window.removeEventListener("message", handleResizeMessage);
      }
    };
  }, [maxWidth]);

  useEffect(() => {
    if (workspaceDetails?.use_global_settings) {
      setMaxWidth(site?.max_width || workspaceDetails?.max_width);
    } else {
      setMaxWidth(workspaceDetails?.max_width);
    }
  }, [workspaceDetails?.use_global_settings, workspaceDetails?.max_width, site?.max_width]);

  const getDomoPageDetails = async (body, signal = null) => {
    try {
      let url = !nested ? `/portal/embed/link/${body.workspaceId}/${body.pageId || "jwt-full-embed"}` : `/portal/embed/items/${body.workspaceId}/${body.pageId}`;

      if (body.groupId && !nested) {
        url = `/portal/embed/items/${body.workspaceId}/${body.groupId}/${body.pageId}`;
      }

      const { data } = await apiRequest("GET", url, { body: {} }, { signal });

      if (data.status === 200) {
        if (data?.data?.invalidWorkspace) {
          navigate("/permission-denied");
        }
        if (data?.data?.invalidPage || data?.data?.invalidGroup) {
          const { data: resData } = await apiRequest("GET", `/portal/users/${workspaceDetails?._id}/page`);

          if (resData.status === 200) {
            // Navigate if valid link is found
            navigate(resData.link);
            return;
          }
        }
        return data;
      } else if (data.status === 401) {
        throw new Error(data.message);
      }
    } catch (error) {
      if (error.message === "CanceledError: canceled") {
        throw new Error(error.message);
      }

      throw new Error(error.message);
    }
  };

  const getPage = async (signal = null) => {
    setPageError(null);
    setLoadingPage(true);
    setSrcDoc("");

    try {
      const res = await getDomoPageDetails(
        {
          userId: user?._id,
          pageId: page_id,
          workspaceId: workspace_id,
          groupId: group_id,
        },
        signal,
      );

      if (res?.data?.invalidFilter) {
        setPageError("incorrect_filters");
      } else {
        setPageType(res.page_type);
        if (res.page_type === "CUSTOM_HTML") {
          setSrcDoc(res.custom_html);
        } else if (res.page_type === "EXTERNAL_LINK") {
          setSrcDoc(
            renderToString(
              <ExternalLinkEmbed
                href={res.page_link}
                target={res.page_link_newtab}
              />,
            ),
          );
          window.open(res.page_link, res.page_link_newtab ? "_blank" : "_self");
        } else if (res.page_type === "JWT_FULL_EMBED") {
          let { embedUrl } = res;
          let htmlIframe = `<iframe id="iframe" src="${embedUrl}" width="100%" height="100%" marginheight="0" marginwidth="0" frameborder="0"></iframe>`;
          setSrcDoc(htmlIframe);
        } else {
          const { html } = res;
          setSrcDoc(html);
        }
      }
      setLoadingPage(false);
    } catch (err) {
      if (err.message === "The user aborted a request." || err.message === "CanceledError: canceled") {
        setLoadingPage(true);
      } else {
        setPageError("incorrect_credentials");
        setLoadingPage(false);
      }
    }
  };

  useEffect(() => {
    const ac = new AbortController();

    if (user?._id && workspace_id && workspaceDetails?._id) {
      getPage(ac.signal);
    }

    return () => {
      ac.abort();
      setSrcDoc("");
      setLoadingPage(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id, workspace_id, group_id, page_id, user?._id, nested]);

  // For metabse demo
  // -----------------------

  // const demoMetabase = async () => {
  //   const { data: res } = await apiRequest(
  //     "GET",
  //     `/portal/metabase/edit-embed`
  //   );
  //   let { embedUrl } = res;
  //   let htmlIframe = `<iframe id="iframe" src="${embedUrl}" width="100%" height="100%" marginheight="0" marginwidth="0" frameborder="0"></iframe>`;
  //   setPageError(null);
  //   setLoadingPage(false);
  //   setSrcDoc(htmlIframe);
  // }

  //   useEffect(() => {
  //     demoMetabase()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {isSafari && pageType === "JWT_FULL_EMBED" ? (
        <BrowserEmbedError />
      ) : pageError ? (
        <ContentError
          pageErrorTypes={pageErrorTypes}
          pageError={pageError}
        />
      ) : (
        <>
          {loadingPage ? (
            <div
              className={classNames(
                "flex items-center justify-center",
                menuType === "vertical" ? "" : "", // for vertical h-full
                nested ? "h-full" : "",
              )}>
              <div className="flex h-full w-full items-center justify-center">
                <Preloader />
              </div>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: srcDoc }}
              className={classNames("w-full", pageType === "DOMO" && maxWidth !== "100%" ? "" : "", menuType === "vertical" ? "h-full" : "", nested ? "h-full" : "")}
              style={{
                maxWidth: maxWidth,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
