import { useNavigate } from "react-router-dom";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";

const SSOLogin = () => {
  const navigate = useNavigate();
  const accessToken = new URLSearchParams(window.location.search).get("access_token");
  const refreshToken = new URLSearchParams(window.location.search).get("refresh_token");

  if (!accessToken) {
    navigate("/");
  } else {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    navigate("/");
  }

  return (
    <div className="flex h-full items-center justify-center">
      <Preloader />
    </div>
  );
};

export default SSOLogin;
