import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import ContentError from "src/components/embedTypes/ContentError";
import ContentLoading from "src/components/embedTypes/ContentLoading";
import ContentTypesRouter from "src/components/embedTypes/ContentTypesRouter";
import { AuthContext } from "src/context/auth";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const Dashboard2 = () => {
  const { user } = useContext(AuthContext);
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const navigate = useNavigate();

  // const { workspace_id, group_id, page_id } = params;
  const { group, page } = useUrlQueryParams();
  const [loadingPage, setLoadingPage] = useState(true);
  const [maxWidth, setMaxWidth] = useState("100%");
  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(null);

  const pageErrorTypes = {
    incorrect_credentials: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the credentials that were added were invalid.",
    },
  };

  useEffect(() => {

    const handlePageGet = async () => {
      const { data: contentResponseData } = await apiRequest("GET", `/portal/embed/:workspace_id`, { params: { workspace_id: workspaceDetails?._id }, queries: { group, page } });
      let { status, data } = contentResponseData;
      if (status === 200) {
        setContent(data);
        setLoadingPage(false);
      }
    };

    if (workspaceDetails?._id && page) {
      handlePageGet();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, page, workspaceDetails?._id]);

  return (
    <>
      {contentError ? (
        <ContentError
          pageErrorTypes={pageErrorTypes}
          pageError={contentError}
        />
      ) : (
        <>
          {loadingPage ? (
            <ContentLoading menuType={menuType} />
          ) : (
            <div
              className="w-full"
              style={{
                maxWidth: maxWidth,
              }}>
              <ContentTypesRouter
                content={content}
                menuType={menuType}
                workspace={workspaceDetails}
                site={site}
                setError={() => {}}
                setLoading={() => {}}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard2;
