import React from "react";
import SafariBrowserLogo from "src/assets/images/safari_browser_logo.png";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function BrowserEmbedError() {
  return (
    <div className="flex items-center justify-center gap-x-5">
      <img
        className="h-20 w-20 opacity-70"
        alt="safari browser"
        src={SafariBrowserLogo}
      />
      <div className="flex flex-col">
        <p className="flex items-center text-2xl font-extrabold text-gray-400">Safari is not supported.</p>
        <p className="flex items-center font-thin text-gray-400">
          The prefered browser is{" "}
          <a
            className="flex items-center gap-x-1 pl-1 text-blue-500 underline transition-all hover:text-blue-700"
            href="https://www.google.com/chrome/dr/download/?brand=JSBI&ds_kid=43700079939272283&gad_source=1&gclid=CjwKCAjww_iwBhApEiwAuG6ccBnV6vchH7_R4EBF6qEjcHJX_12SJ4Zeh25PI3F5o4m2qNaGaSnJ6RoCiXMQAvD_BwE&gclsrc=aw.ds">
            Google Chrome <ArrowTopRightOnSquareIcon className="h-5 w-5" />
          </a>
        </p>
      </div>
    </div>
  );
}