import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import NavigationRouter from "src/components/navigation/NavigationRouter";
import Header from "src/components/shared/Header";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { outerPages } from "src/config/host";
import { AuthContext } from "src/context/auth";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { searchMenu } from "src/helpers";
import { classNames } from "src/helpers/classNames";

const Dashboardlayout = ({ nested = false }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMenu, setIsMenu] = useState(true);
  const [menuType, setMenuType] = useState("vertical");
  // const [workspaceLoading, setWorkspaceLoading] = useState(true);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceDetailsLoading, setWorkspaceDetailsLoading] = useState(true);
  const [workspaceDetails, setWorkspaceDetails] = useState({});

  const [keyword, setKeyword] = useState("");
  const [menus, setMenus] = useState([]);
  const [simulatedMenus, setSimulatedMenus] = useState([]);

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { site } = useContext(SiteContext);

  const [params, setParams] = useState({ workspace_id: null, group_id: null, page_id: null });

  const { workspace_id, group_id, page_id } = useParams();

  useEffect(() => {
    setParams({ workspace_id, group_id, page_id });
  }, [workspace_id, group_id, page_id]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowWidth < 768) {
      setIsMenu(false);
    } else {
      setIsMenu(true);
    }
  }, [windowWidth, workspace_id]);

  const loadWorkspaces = useCallback(() => {
    (async () => {
      if (!user?._id) return;

      setWorkspaceDetailsLoading(true);
      try {
        const { data: resData } = await apiRequest("GET", "/portal/users/workspaces/list");

        if (resData.status === 200) {
          if (!Array.isArray(resData.data) || resData.data.length === 0) {
            navigate("/permission-denied");
          }

          setWorkspaces(resData.data);
        }
      } catch (err) {
        toast.error("err", err);
      } finally {
        setWorkspaceDetailsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  const isOuterPage = outerPages.includes(window.location.pathname);

  useEffect(() => {
    if (workspaceDetails?._id) {
      setMenuType(workspaceDetails?.layout_type === "TOP_NAVIGATION" ? "horizontal" : "vertical");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id]);

  useEffect(() => {
    if (isOuterPage) {
      setWorkspaceDetails({});
    }
  }, [isOuterPage]);

  useEffect(() => {
    loadWorkspaces();
  }, [loadWorkspaces]);

  useEffect(() => {
    if (workspaces.length) {
      const currentWorkspace = workspaces.find((workspace) => workspace._id === workspace_id || workspace.slug === workspace_id);
      setWorkspaceDetails(currentWorkspace || workspaces[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.length, workspace_id]);

  const loadMenus = useCallback(() => {
    (async () => {
      if (!workspace_id || !workspaceDetails?.slug || ![workspaceDetails?._id, workspaceDetails?.slug].includes(workspace_id)) return;

      try {
        setMenus([]);
        setSimulatedMenus([]);
        const { data: menuData } = await apiRequest("GET", `/portal/menu/${workspace_id}`);

        if (menuData.status === 200) {
          const individualMenus = menuData.data.individualMenu.map((item) => ({
            ...(item.page_id || {}),
            type: item.page_id?.page_type !== "EXTERNAL_LINK" ? "internal" : "external",
            link: item.page_id?.page_type !== "EXTERNAL_LINK" ? `/workspace/${workspaceDetails?.slug || workspace_id}/page/${item.page_id?.slug || item.page_id?._id}` : item.page_id?.page_link,
          }));

          const groupMenus = menuData.data.menu.map((item) => ({
            ...item,
            type: "group",
            isOpen: item.slug === group_id || item._id === group_id,
            submenu: item.permissions.map((permission) => ({
              ...permission.page,
              groupId: item.slug || item._id,
              isOpen: item.slug === group_id || item._id === group_id,
              type: permission.page?.page_type !== "EXTERNAL_LINK" ? "internal" : "external",
              link: permission.page?.page_type !== "EXTERNAL_LINK" ? `/workspace/${workspaceDetails?.slug || workspace_id}/group/${item.slug || item._id}/page/${permission.page?.slug || permission.page?._id}` : permission.page?.page_link,
            })),
          }));

          if (!individualMenus.length && groupMenus.length === 1) {
            setMenus(
              groupMenus.flatMap((item) =>
                item.submenu.map((item) => ({
                  ...item,
                  sub: true,
                })),
              ),
            );

            if (!keyword) {
              setSimulatedMenus(
                groupMenus.flatMap((item) =>
                  item.submenu.map((item) => ({
                    ...item,
                    sub: true,
                  })),
                ),
              );
            } else {
              setSimulatedMenus(
                searchMenu(
                  groupMenus.flatMap((item) =>
                    item.submenu.map((item) => ({
                      ...item,
                      sub: true,
                    })),
                  ),
                  keyword,
                ),
              );
            }
          } else {
            setMenus([...individualMenus, ...groupMenus]);
            if (!keyword) {
              setSimulatedMenus([...individualMenus, ...groupMenus]);
            } else {
              setSimulatedMenus(searchMenu([...individualMenus, ...groupMenus], keyword));
            }
          }
        }
      } catch (err) {
        // toast.error(err.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_id, workspaceDetails?.slug, workspaceDetails?._id]);

  useEffect(() => {
    loadMenus();
  }, [loadMenus]);

  const containerClass = {
    default: "w-full flex flex-grow transition-all",
    vertical: classNames(
      "w-full flex flex-grow transition-all justify-center overflow-auto",
      isMenu && !isOuterPage && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && menus.length > 1 && menuType === "vertical" ? "bg-backgroundColor  sm:max-w-[calc(100%-18rem)] ml-0 sm:ml-80 justify-center max-h-[calc(100vh-4rem)]" : "max-w-full ml-0",
    ),
    horizontal: classNames(
      "w-full flex flex-grow transition-all justify-center overflow-auto",
      isMenu && !isOuterPage && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && menus.length > 1 && menuType === "vertical"
        ? "bg-backgroundColor sm:max-w-[calc(100%-18rem)] ml-0 sm:ml-80 justify-center max-h-[calc(100vh-4rem)]"
        : "max-w-full ml-0 h-full max-h-[calc(100vh-4rem)] md:max-h-[calc(100vh-7.5rem)]",
    ),
  };

  if (!site?._id) {
    return (
      <div className={classNames("flex h-full items-center justify-center")}>
        <Preloader />
      </div>
    );
  }

  return (
    <WorkspaceContext.Provider
      value={{
        loading: workspaceDetailsLoading,
        setLoading: setWorkspaceDetailsLoading,
        list: workspaces,
        setList: setWorkspaces,
        details: workspaceDetails,
        setDetails: setWorkspaceDetails,
        menuType,
        params,
      }}>
      {!nested ? (
        <div className="min-h-screen">
          <Header
            isMenu={isMenu}
            setIsMenu={setIsMenu}
            menuType={menuType}
            menus={menus}
          />
          {workspaceDetailsLoading && !outerPages.includes(window.location.pathname) ? (
            <div className={classNames("flex h-full items-center justify-center pt-16")}>
              <Preloader />
            </div>
          ) : (
            <div className={classNames("flex min-h-screen pt-16", menuType !== "vertical" ? "flex-col" : "")}>
              {outerPages.includes(window.location.pathname) ? (
                <></>
              ) : workspaceDetails?.workspace_type === "JWT_FULL_EMBED" ? (
                <></>
              ) : (
                <>
                  {menus.length > 1 && (
                    <NavigationRouter
                      menuType={menuType}
                      isMenu={isMenu}
                      setIsMenu={setIsMenu}
                      menus={menus}
                      setMenus={setMenus}
                      simulatedMenus={simulatedMenus}
                      setSimulatedMenus={setSimulatedMenus}
                      keyword={keyword}
                      setKeyword={setKeyword}
                    />
                  )}
                </>
              )}
              <div className={containerClass[menuType]}>
                <Outlet />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Outlet />
      )}
    </WorkspaceContext.Provider>
  );
};

export default Dashboardlayout;
